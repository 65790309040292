const CaretRight = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.70708 18.7071C9.51182 18.9024 9.19524 18.9024 8.99998 18.7071L8.29287 18C8.09761 17.8048 8.09761 17.4882 8.29287 17.2929L13.2322 12.3536L8.29287 7.41424C8.09761 7.21898 8.09761 6.90239 8.29287 6.70713L8.99998 6.00002C9.19524 5.80476 9.51182 5.80476 9.70708 6.00002L15.7071 12C15.9023 12.1953 15.9023 12.5119 15.7071 12.7071L9.70708 18.7071Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default CaretRight
